@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-style: none;
  /* IE and Edge */
  /* scrollbar-width: none; */

  /* Firefox */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 0.5rem;
  height: 1px;
  background-color: rgb(31, 41, 55);
  background-color: rgb(31, 41, 55);

}

::-webkit-scrollbar-thumb {
  /*hover*/
  background-color: rgb(55, 65, 81);
  border-color: rgb(31, 41, 55);
  border: 1;
  /* width: 0.2rem; */
  border-radius: 10px;

  /* @apply bg-gray-300 hover: bg-gray-400 dark:border-gray-900 dark:bg-gray-700 dark:hover:bg-gray-600; */
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgb(75 85 99);
}